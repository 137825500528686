<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <SidebarToggler class="d-md-down-none" display="lg" />
      <b-link class="navbar-brand mr-auto" to="#">
        <!--  -->
        <img
          class="navbar-brand-full"
          src="@/assets/image/Cineco-Logo.png"
          alt="CoreUI Logo"
          style="width:60%"
        />
        <img
          class="navbar-brand-minimized w-100"
          src="@/assets/image/Cineco-Logo.png"
          alt="CoreUI Logo"
        />

        <!-- <img class="navbar-brand-full" src="img/starcinema-logo.png" alt="CoreUI Logo">
        <img class="navbar-brand-minimized" src="img/starcinema-logo.png" alt="CoreUI Logo">-->
        <!-- <img class="navbar-brand-full" src="img/empire-logo2.svg" alt="CoreUI Logo">
        <img class="navbar-brand-minimized" src="img/empire-logo2.svg" alt="CoreUI Logo">-->
      </b-link>
      <b-navbar-nav>
        <!-- <b-nav-item class="px-3" to="/dashboard">Dashboard</b-nav-item>
        <b-nav-item class="px-3" to="/users" exact>Users</b-nav-item>
        <b-nav-item class="px-3">Settings</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item class="d-md-down-none">
          <DefaultHeaderDropdownNotif/>
        </b-nav-item>
        <b-nav-item class="d-md-down-none">
          <DefaultHeaderDropdownTasks/>
        </b-nav-item>
        <b-nav-item class="d-md-down-none">
          <DefaultHeaderDropdownMssgs/>
        </b-nav-item>
        <b-nav-item class="d-md-down-none">
          <DefaultHeaderDropdown/>
        </b-nav-item>-->
        <div class="d-md-down-none" style="color: white">Welcome, {{ user_name }}</div>

        <DefaultHeaderDropdownAccnt />
      </b-navbar-nav>
      <!-- <AsideToggler class="d-none d-lg-block" /> -->
      <!--<AsideToggler class="d-lg-none" mobile />-->
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <!-- <SidebarHeader/>  -->
        <SidebarForm />
        <Sidebar></Sidebar>
        <SidebarNav v-for="(data, index) in nav" :key="index" v-if="false"
          >{</SidebarNav
        >
        <SidebarFooter />
        <SidebarMinimizer />
      </AppSidebar>
      <main class="main">
        <Breadcrumb :list="list" />
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <!-- <AppAside fixed>
        <DefaultAside />
      </AppAside>-->

      <AppAside fixed>
        <!--aside-->
        <!-- <DefaultAsideOne /> -->
      </AppAside>
    </div>
    <TheFooter>
      <!--footer-->
      <div>
        <a target="_blank">Cineco</a>

        <span class="ml-1">&copy; 2022.</span>
      </div>
      <!-- <div v-if="dashboardName == 'Ticketak'" class="mx-auto">
        If you have any issues while using the dashboard, please contact
        <a href="#">admin@ticketak.com</a>
      </div> -->
      <!-- <div v-if="dashboardName == 'MyTiketi'" class="mx-auto">
        If you have any issues while using the dashboard, please contact
        <a href="mailto:support@mytiketi.com">support@mytiketi.com</a>
      </div> -->
      <!-- <div class="ml-auto">
        <span class="mr-1">Powered by</span>
        <a
          v-if="dashboardName == 'Ticketak'"
          href="https://www.ticketak.com/"
          target="_blank"
          >Ticketak</a
        >
        <a
          v-if="dashboardName == 'MyTiketi'"
          href="https://www.ticketak.com/"
          target="_blank"
          >MyTiketi</a
        >
      </div> -->
    </TheFooter>
  </div>
</template>

<script>
import nav from "@/_nav";
import Sidebar from "@/Component/sidebar";
import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Aside as AppAside,
  AsideToggler,
  Footer as TheFooter,
  Breadcrumb,
} from "@coreui/vue";
import DefaultAside from "./DefaultAside";
import DefaultHeaderDropdown from "./DefaultHeaderDropdown";
import DefaultHeaderDropdownNotif from "./DefaultHeaderDropdownNotif";
import DefaultHeaderDropdownAccnt from "./DefaultHeaderDropdownAccnt";
import DefaultHeaderDropdownMssgs from "./DefaultHeaderDropdownMssgs";
import DefaultHeaderDropdownTasks from "./DefaultHeaderDropdownTasks";

// import DefaultAsideOne from "@/Component/Movies/MovieAside/DefaultAsideOne";

export default {
  name: "DefaultContainer",
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Breadcrumb,
    Sidebar,
    // DefaultAside,
    // DefaultAsideOne,
    DefaultHeaderDropdown,
    DefaultHeaderDropdownMssgs,
    DefaultHeaderDropdownNotif,
    DefaultHeaderDropdownTasks,
    DefaultHeaderDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
  },
  data() {
    return {
      nav: nav.items,
      user_name: this.$cookie.get("TICUN"),
      userRole: localStorage.getItem("UserRole")
        ? localStorage.getItem("UserRole")
        : "",
    };
  },
  mounted() {
    if (localStorage.getItem("UserRole") == "Finance") {
      console.log("object :", localStorage.getItem("UserRole"));
      this.nav = nav.items.filter((item) => {
        console.log("item.name :", item.name);
        return item.name == "Reports";
      });
    } else {
      this.nav = nav.items.filter(
        (item) =>
          item.name !== "CineAsia-Distributors" &&
          item.name !== "CineAsia-Cinemas" &&
          item.name !== "Cinema Finance Report"
      );
    }
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        (route) => route.name || route.meta.label
      );
    },
  },
};

// if (localStorage.getItem("UserRole") == "Distributor") {
//       this.nav = nav.items.filter(
//         item =>
//           item.name == "CineAsia-Distributors" || item.name == "Gogo Dashboard"
//       );
//     } else if (localStorage.getItem("UserRole") == "Cinema") {
//       this.nav = nav.items.filter(
//         item =>
//           // item.name == "CineAsia-Cinemas" ||
//           item.name == "Users-Report" ||
//           item.name == "Gogo Dashboard" ||
//           item.name == "Cinema Finance Report" ||
//           item.name == "Reports"
//       );
//     } else if (localStorage.getItem("UserRole") == "Finance") {
//       // console.log("nav.itemsnav.items", this.nav.items);
//       this.nav = nav.items.filter(
//         // item => item.name === "ShowDateReport"
//         item => item.name == "Reports"
//         // item.name == "Admin Finance Report"
//       );
//     } else if (localStorage.getItem("UserRole") == "Organization") {
//       this.nav = nav.items.filter(
//         item =>
//           item.name == "Ticketak Dashboard" ||
//           // item.name == "CineAsia-Cinemas" ||
//           item.name == "Users-Report" ||
//           item.name == "Gogo Dashboard" ||
//           item.name == "Organization Finance Report" ||
//           item.name == "Reports"
//       );
//     } else {
//       this.nav = nav.items.filter(
//         item =>
//           item.name !== "CineAsia-Distributors" &&
//           item.name !== "CineAsia-Cinemas" &&
//           item.name !== "Cinema Finance Report"
//       );
//     }
</script>
<style>
.navbar-brand-full {
}
</style>
